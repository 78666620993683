import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {LocalStorageService} from 'app/shared/services/localStorage.service';
import {ACCESS_TOKEN_NAME} from 'app/app-configs';
import {AuthService} from "../services/auth.service";

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authSer: AuthService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    const currentCredentials = LocalStorageService.getInstance().getItem(ACCESS_TOKEN_NAME);
    if (currentCredentials) {
      return true;
    } else {
      return false
    }
  }
}
