import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_INITIALIZER, LOCALE_ID, NgModule, Provider} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {NbDateFnsDateModule} from '@nebular/date-fns';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgOptimizedImage} from '@angular/common';
import {
  NB_TIME_PICKER_CONFIG,
  NbCardModule,
  NbDatepickerModule,
  NbLayoutModule,
  NbMenuModule,
  NbSidebarModule,
  NbThemeModule,
} from '@nebular/theme';

import {CoreModule} from 'app/core/core.module';
import {ThemeModule} from 'app/theme/theme.module';
import {AppComponent} from 'app/app.component';
import {AppRoutingModule} from 'app/app-routing.module';
import {AppService} from 'app/app.service';
import {ApiInterceptor} from 'app/core/interceptor';
import {Router, RouterModule} from '@angular/router';
import {AuthRoutingModule} from "./auth/auth-routing.module";
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {DEFAULTLANGUAGE, LANGUAGE} from "./app-configs";
import {
  L10nIntlModule,
  L10nTranslationModule,
  L10nValidationModule
} from "angular-l10n";
import {l10nConfig, LocaleResolver, LocaleValidation, TranslationLoader} from "./l10n-config";
import {TranslateCompiler, TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateMessageFormatCompiler} from "ngx-translate-messageformat-compiler";
import {TranslationModule} from "./shared/modules/translation.module";


const initApp = (appService: AppService) => async () => await appService.load();

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export function getCurentLocale(): string {
  return localStorage.getItem(LANGUAGE) || DEFAULTLANGUAGE;
}


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    AuthRoutingModule,
    HttpClientModule,
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbCardModule,
    NbDatepickerModule.forRoot(),
    NbDateFnsDateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    NbThemeModule.forRoot({name: 'default'}),
    NbLayoutModule,
    NbEvaIconsModule,
    NgOptimizedImage,

    L10nTranslationModule.forRoot(
      l10nConfig,
      {
        localeResolver: LocaleResolver,
        translationLoader: TranslationLoader
      }
    ),
    L10nIntlModule,
    // L10nValidationModule.forRoot({validation: LocaleValidation}),
    TranslationModule,

  ],
  providers: [
    NbThemeModule.forRoot().providers as Provider[],
    AppService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,

    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [AppService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: getCurentLocale()
    },
    {
      provide: NB_TIME_PICKER_CONFIG,
      useValue: {},
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private readonly router: Router,
  ) {
    // HELPED: show routes!
    // router.events.subscribe(console.log)
  }
}
