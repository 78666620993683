import {Component, inject, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {L10N_LOCALE} from "angular-l10n";
import {MenuItem} from "primeng/api";

@Component({
  selector: '<ngx-base></ngx-base>',
  template: '',
})
export abstract class BaseComponent implements OnDestroy {
  protected destroyed$ = new Subject();
  locale = inject(L10N_LOCALE);
  constructor() {
  }
  breadcrumb: MenuItem[] = [
      { label: 'Home', routerLink: '/' },
      { label: 'Manager', routerLink: '/manager' },
    ];
  ngOnDestroy(): void {
    // @ts-ignore
    this.destroyed$.next();
  }
}
