<div class="header-container">
  <div class="logo-container">
    <!--    <a href="#" (click)="toggleSidebar()" *ngIf="user?.username?.length" [style.visibility]="showToggleSideBar ? 'visible' : 'hidden'">-->
    <!--                <span class="icon-width-24 icon-list"></span>-->
    <!--    </a>-->
    <a (click)="toggleSidebar()" *ngIf="router.url.search('manager') === 1" class="sidebar-toggle"
       href="#"
    >
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="/"><img alt="logo" height="40" ngSrc="../../../../assets/imgs/favicon.ico" width="40"/></a>
    <!--        <a class="logo" href="#" (click)="navigateHome()"><span>Homies</span></a>-->

  </div>
  <!--   <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">-->
  <!--    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>-->
  <!--  </nb-select>-->
</div>


<ng-template #logged>
  <nb-action class="user-action">
    <nb-user
      *nbIsGranted="['view', 'log_out']"
      [name]="user?.username"
      [nbContextMenu]="userMenu"
      [onlyPicture]=true
      [picture]="user?.picture"
      size="small">
    </nb-user>
  </nb-action>
</ng-template>

<ng-template #notYeLogin>
  <nb-action *ngIf="loggedIn()===false" size="small">
    <ngx-choose-language></ngx-choose-language>
  </nb-action>
  <nb-action class="user-action">
    <button nbButton routerLink="/auth" size="small" status="danger" >{{"Access" | translateAsync:locale.language}}</button>
  </nb-action>
</ng-template>

<div class="header-container">
  <!--  LANG-->
  <nb-actions fullWidth="false">
    <nb-action>
      <ng-container
        [ngTemplateOutletContext]="{ $implicit: 'Activities'}"
        [ngTemplateOutlet]="loggedIn()===true  ? logged: notYeLogin"
      >
      </ng-container>
    </nb-action>
  </nb-actions>
</div>
