import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppService} from 'app/app.service'
import {LocalStorageService} from 'app/shared/services/localStorage.service';
import {LANGUAGE} from 'app/app-configs';
import {L10nTranslationService} from "angular-l10n";
import {BaseComponent} from "../base/base.component";


@Component({
  selector: 'ngx-choose-language',
  templateUrl: './choose-language.component.html',
  styleUrls: ['./choose-language.component.scss'],
})
export class ChooseLanguageComponent extends BaseComponent implements OnInit {
  public languages!: any;
  public selected!: string;

  constructor(
    public appService: AppService,
    public translate: L10nTranslationService,
    // public locale: LocaleService
  ) {
    super()
  }

  ngOnInit(): void {
    this.selected = LocalStorageService.getInstance().getItem(LANGUAGE) || this.appService.settings.defaultLanguage;
    LocalStorageService.getInstance().setItem(LANGUAGE, this.selected);
    this.languages = this.appService.settings.languages;
  }

  public changeLanguage(event: string) {
    LocalStorageService.getInstance().setItem(LANGUAGE, event);
    // this.locale.registerCulture(event);
    // window.location.reload()
    let results = this.appService.settings.schema;
    let current_locale = results.find((x) => x.locale.language == event);
    console.debug("now this shit change to: " + current_locale?.locale.language);

    if (current_locale?.locale) {
      this.translate.setLocale(current_locale.locale);
    } else {
      this.translate.setLocale(this.appService.settings.defaultLocale);
      console.debug("now this shit change to: " + this.appService.settings.defaultLocale.locale.language);
    }
  }
}
