import {BehaviorSubject, Observable, throwError as observableThrowError} from 'rxjs';
import {catchError, filter, finalize, switchMap, take} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {LocalStorageService} from 'app/shared/services/localStorage.service';
import {RefreshLoginRequest, RefreshResponse} from 'app/core/models/log-in.model';
import {AuthService} from 'app/core/services/auth.service';
import {ACCESS_TOKEN_NAME, CURRENT_USER, DEFAULTLANGUAGE, LANGUAGE, REFRESH_ACCESS_TOKEN_NAME} from 'app/app-configs';


@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private isRefreshingToken: boolean = false;
  private tokenSubject!: BehaviorSubject<string | null>;
  private user = LocalStorageService.getInstance().getItem(CURRENT_USER);

  constructor(private injector: Injector, private router: Router) {
    this.tokenSubject = new BehaviorSubject<string | null>(null);
  }

  public getLang(): string {
    return LocalStorageService.getInstance().getItem(LANGUAGE) || DEFAULTLANGUAGE;
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const myRequest =
      request.body instanceof FormData ? this.createRequestForm(request) : this.createJsonRequest(request);
    myRequest.headers.set('Accept-Language', this.getLang());

    return next.handle(myRequest).pipe(
      // @ts-ignore
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          const statusCode = (<HttpErrorResponse>error).status;
          switch (statusCode) {
            case 400:
              return this.handle400Error(error);
            case 401:
              return this.handle401Error(request, next);
            case 403:
              return this.handle403Error(error);
            case 500:
              return this.handle500Error(error);
            case 504:
              return this.handle500Error(error);
            case 0:
              return this.handle500Error(error);
            default:
              break;
          }
        }
        return observableThrowError(error);
      })
    );
  }

  protected handle500Error(error: HttpErrorResponse) {
    return this.logoutUser();
  }

  protected handle403Error(error: HttpErrorResponse) {
    if (error && error.status === 403) {
      return this.logOut();
    }
    return observableThrowError(error);
  }

  protected handle400Error(error: HttpErrorResponse) {
    if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
      return this.logoutUser();
    }
    return observableThrowError(error);
  }

  protected handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      // no more calls come in and call refreshLogin again
      this.isRefreshingToken = true;
      this.tokenSubject.next(null);
      const authService = this.injector.get(AuthService);
      const refreshToken = LocalStorageService.getInstance().getItem(REFRESH_ACCESS_TOKEN_NAME);
      const refreshLoginRequest = new RefreshLoginRequest({refresh: refreshToken});
      // if (!!refreshToken) {
      //   return this.logoutUser()
      // }
      return authService.refreshLogin(refreshLoginRequest).pipe(
        switchMap((response: RefreshResponse) => {

          if (response.access) {
            const newAccessToken = response.access || null;
            // save new access token
            LocalStorageService.getInstance().setItem(ACCESS_TOKEN_NAME, newAccessToken);
            this.tokenSubject.next(newAccessToken);

            // next request
            return next.handle(this.createJsonRequest(request));
          }


          // If we don't get a new token, we are in trouble so logout.
          return this.logoutUser();
        }),
        // @ts-ignore
        catchError((error) => {
          this.logoutUser();
          this.router.navigate(["/login"])
        }),
        finalize(() => {
          // reset the isRefreshingToken to false for the next time the token needs to be refreshed
          this.isRefreshingToken = false;
        }),
      );
    } else {
      //  we will wait until tokenSubject has a non-null value, which means the new token is ready

      return this.tokenSubject
        .pipe(filter((token) => token != null))
        .pipe(take(1))
        .subscribe((token) => {
          LocalStorageService.getInstance().setItem(ACCESS_TOKEN_NAME, token);
          // next request
          const myRequest = this.createJsonRequest(request);

          return next.handle(myRequest);
        });
    }
  }

  protected createRequestForm(request: HttpRequest<any>): HttpRequest<any> {
    if (LocalStorageService.getInstance().getItem(ACCESS_TOKEN_NAME) === null) {
      return request.clone({
        setHeaders: {
          'Content-Type': 'multipart/form-data',
        },
      });
    }
    return request.clone({
      setHeaders: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${LocalStorageService.getInstance().getItem(ACCESS_TOKEN_NAME)}`,
      },
    });
  }

  protected createJsonRequest(request: HttpRequest<any>): HttpRequest<any> {
    if (LocalStorageService.getInstance().getItem(ACCESS_TOKEN_NAME) === null) {
      return request.clone({
        setHeaders: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          "Accept-Language": this.getLang(),
        },
      });
    }
    return request.clone({
      setHeaders: {
        Accept: 'application/json',
        Authorization: `Bearer ${LocalStorageService.getInstance().getItem(ACCESS_TOKEN_NAME)}`,
        'Content-Type': 'application/json',
        "Accept-Language": this.getLang(),
      },
    });
  }

  protected logOut() {
    LocalStorageService.getInstance().clearSessionStorage();
    this.router.navigate(['/login']);
    return observableThrowError('Unauthorize');
  }

  protected logoutUser() {
    LocalStorageService.getInstance().clearSessionStorage();
    // this.router.navigate(['/login']);
    return observableThrowError('Unauthorize');

  }
}
