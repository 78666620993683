import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MyRouteTabsetComponent} from './my-route-tabset.component';
import {NbMergeConfigsPipe} from './merge-configs.pipe';
import {NbBadgeModule, NbIconModule} from "@nebular/theme";
import {RouterModule} from "@angular/router";


@NgModule({
  imports: [CommonModule, RouterModule, NbIconModule, NbBadgeModule],
  declarations: [MyRouteTabsetComponent, NbMergeConfigsPipe],
  exports: [MyRouteTabsetComponent],
})
export class MyRouteTabsetModule {
}
