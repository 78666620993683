export interface User {
  uuid?: string | null;

  username: string;

  email: string;

  full_name: string;

  first_name: string;

  mid_name: string;

  last_name: string;

  role: string;

  phone: number | null;

  address: string;

  lang: string;

  avatar: string;
  tz: string;
  sex: string | null;
}


export class castUser implements User {
  uuid: string | null | undefined
  username: string = ""
  email: string = ""
  full_name: string = ""
  first_name: string = ""
  mid_name: string = ""
  last_name: string = ""
  role: string = ""
  phone: number | null = null
  address: string = ""
  lang: string = ""
  avatar: string = ""
  sex: string = ""
  tz: string = ""

  constructor(obj: User) {
    this.uuid = obj.uuid
    this.username = obj.username
    this.phone = obj.phone
    this.address = obj.address
    this.role = obj.role
    this.avatar = obj.avatar
    this.tz = obj.tz
    this.lang = obj.lang
  }
}


export const defaultUser: User = {
  uuid: null,
  username: '',
  email: '',
  full_name: '',
  first_name: '',
  mid_name: '',
  last_name: '',
  role: '',
  phone: null,
  address: '',
  lang: '',
  avatar: '',
  sex: '',
  tz: '',
};

// export interface UserFarm {
//     user_farm_id: number | null;
//
//     role: number;
//
//     user: User;
// }
