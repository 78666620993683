import {ResponseMessage} from './response.message.model';

export class LoginViewModel {
  public username: string = '';
  public password: string = '';
  public email: string = '';
  public remember?: boolean;
}

export class LoginRequest {
  public payload!: LoginViewModel;

  public constructor(fields?: Partial<LoginRequest>) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

export class LoginResponse {
  public responseMessage!: ResponseMessage;
  public access_token?: string;
  public refresh_token?: string;
}

export class RefreshLoginRequest {
  public refresh: string | null | undefined;

  public constructor(fields?: Partial<RefreshLoginRequest>) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

export class RefreshResponse {
  public access?: string
}


export class VerifyTokenViewModel {
  public token: string = '';
}

export class VerifyToken {
  public payload!: VerifyTokenViewModel;

  public constructor(fields?: Partial<VerifyToken>) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}
