import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {ConfirmModalComponent} from './components/confirm-modal/confirm-modal.component';
import {
  NbBadgeModule,
  NbButtonModule,
  NbCardModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbListModule,
  NbSelectModule,
  NbStatusService,
  NbTabsetModule,
  NbTagModule,
  NbUserModule
} from '@nebular/theme';
import {ToastrService} from 'app/shared/services/toastr.service';
import {ChooseLanguageComponent} from './components/choose-language/choose-language.component';
import {PagingComponent} from './components/paging/paging.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {AddmemComponent} from "./components/addmem/addmem.component";
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MemberComponent} from './components/member/member.component';
import {MembertagComponent} from './components/membertag/membertag.component';
import {CreateActComponent} from './components/createact/create-act.component';
import {TranslateModule} from "@ngx-translate/core";
import {CreateAssocPaymentComponent} from './components/create-assoc-payment/create-assoc-payment.component';
import {CustomDisplayNamePipe} from './pipes/member/display-name.pipe';
import {DisplayAvatarPipe} from './pipes/member/display-avatar.pipe';
import {ListAssocPaymentComponent} from './components/list-assoc-payment/list-assoc-payment.component';
import {RouterModule} from "@angular/router";
import {MyRouteTabsetModule} from "./modules/tabroutee/my-route-tabset.module";
import {MyRouteTabsetComponent} from "./modules/tabroutee/my-route-tabset.component";
import {L10nIntlModule, L10nTranslationModule} from "angular-l10n";
import { ListPaymentWillBeMadeComponent } from './components/list-payment-will-be-made/list-payment-will-be-made.component';
import { ListMemberComponent } from './components/list-member/list-member.component';


@NgModule({
  declarations: [
    ConfirmModalComponent,
    ChooseLanguageComponent,
    AddmemComponent,
    PagingComponent,
    MemberComponent,
    MembertagComponent,
    CreateActComponent,
    CreateAssocPaymentComponent,
    CustomDisplayNamePipe,
    DisplayAvatarPipe,
    ListAssocPaymentComponent,
    ListPaymentWillBeMadeComponent,
    ListMemberComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    NbButtonModule,
    NbCardModule,
    NbIconModule,
    NbSelectModule,
    NgxPaginationModule,
    NbTagModule,
    NgSelectModule,
    NbBadgeModule,
    FormsModule,
    NgOptimizedImage,
    NbListModule,
    NbUserModule,
    NbInputModule,
    NbFormFieldModule,
    NbTabsetModule,
    MyRouteTabsetModule,
    ReactiveFormsModule,
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient],
    //   },
    //   compiler: {
    //     provide: TranslateCompiler,
    //     useClass: TranslateMessageFormatCompiler
    //   }
    // }),
    L10nTranslationModule,
    L10nIntlModule

  ],
  providers: [
    ToastrService,
    NbStatusService,
  ],
  exports: [
    ChooseLanguageComponent,
    PagingComponent,
    AddmemComponent,
    MemberComponent,
    MembertagComponent,
    TranslateModule,
    CreateAssocPaymentComponent,
    MyRouteTabsetComponent,
  ],
})
export class SharedModule {
}
