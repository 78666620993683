import {castUser, User} from "../../core/models/user.model";
import {CURRENT_USER} from "../../app-configs";

export class LocalStorageService {
  private static _instance: LocalStorageService = new LocalStorageService();

  constructor() {
    if (LocalStorageService._instance) {
      throw new Error('Error: Instantiation failed: '
        + 'Use LocalSessionService.getInstance() instead of new.');
    }
    LocalStorageService._instance = this;
  }

  public static getInstance(): LocalStorageService {
    return LocalStorageService._instance;
  }

  setItem(key: string, value: any) {
    window.localStorage.setItem(key, value);
  }

  getItem(key: string) {
    return window.localStorage.getItem(key) || null;
  }

  getCurrentUser(): User {
    let a = window.localStorage.getItem(CURRENT_USER);
    return new castUser(JSON.parse(a ? a : ''))
  }

  removeItem(key: string) {
    window.localStorage.removeItem(key);
  }

  clearSessionStorage() {
    window.localStorage.clear();
  }
}
