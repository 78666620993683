import {Inject, Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {from, Observable} from 'rxjs';

import {
  L10N_CONFIG,
  L10N_LOCALE,
  L10nConfig,
  L10nDateTimeFormatOptions,
  L10nLocale,
  L10nLocaleResolver,
  L10nNumberFormatOptions,
  L10nProvider,
  L10nTranslationLoader,
  L10nValidation,
  parseDigits
} from 'angular-l10n';

export const l10nConfig: L10nConfig = {

  format: 'language-region',
  providers: [
    {name: 'app', asset: 'app'}
  ],
  cache: true,
  keySeparator: '.',
  defaultLocale: {language: 'en-US', currency: 'USD', timeZone: 'America/Los_Angeles', units: {length: 'mile'}},
  schema: [
    {dir: "ltr", locale: {language: 'en-US', currency: 'USD', timeZone: 'America/Los_Angeles', units: {'length': 'mile'} }},
    {dir: "ltr", locale: {language: 'vi-VN', currency: 'VND', timeZone: 'Asia/Bangkok', units: {'length': 'metter'} }}
  ]
};

@Injectable({
  providedIn: 'root',
})
export class LocaleResolver implements L10nLocaleResolver {

  constructor(@Inject(L10N_CONFIG) private config: L10nConfig, private location: Location) {
  }

  public async get(): Promise<L10nLocale | null> {
    const path = this.location.path();

    for (const schema of this.config.schema) {
      const language = schema.locale.language;
      if (new RegExp(`(\/${language}\/)|(\/${language}$)|(\/(${language})(?=\\?))`).test(path)) {
        return Promise.resolve(schema.locale);
      }
    }
    return Promise.resolve(null);
  }

}

@Injectable({
  providedIn: 'root',
})
@Injectable()
export class TranslationLoader implements L10nTranslationLoader {
  public get(language: string, provider: L10nProvider): Observable<{ [key: string]: any }> {
    // const data = import(`app/i18n/${language}/${provider.asset}.json`);
    const data = import(`assets/i18n/${language}.json`);
    return from(data);
  }
}

@Injectable()
export class LocaleValidation implements L10nValidation {

  constructor(@Inject(L10N_LOCALE) private locale: L10nLocale) {
  }

  public parseNumber(value: string, options?: L10nNumberFormatOptions, language = this.locale.language): number | null {
    console.log("conmenooooooooooooo")
    console.log(language)
    if (value === '' || value == null) return null;

    class Format {
      //  dirtfix type assign by me!!
      minimumIntegerDigits: number | undefined;
      minimumFractionDigits: number | undefined;
      maximumFractionDigits: number | undefined;
    }

    let format: Format = {minimumIntegerDigits: 1, minimumFractionDigits: 0, maximumFractionDigits: 0};
    if (options && options.digits) {
      format = {...format, ...parseDigits(options.digits)};
    }

    let decimalSeparator: string;
    switch (language) {
      case 'vi-VN':
        decimalSeparator = '.';
        break;
      default:
        decimalSeparator = '.';
    }
    const pattern = `^-?[\\d]{${format.minimumIntegerDigits},}(\\${decimalSeparator}[\\d]{${format.minimumFractionDigits},${format.maximumFractionDigits}})?$`;
    const regex = new RegExp(pattern);
    // console.log(value)
    // console.log(parseFloat(value.replace(decimalSeparator, '.')))
    return regex.test(value) ? parseFloat(value.replace(decimalSeparator, '.')) : null;
  }

  public parseDate(value: string, options?: L10nDateTimeFormatOptions, language = this.locale.language): Date | null {
    return null;
  }

}
