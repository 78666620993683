export enum ResponseStatus {
  Success = 0,
  Fail = 10,
  Invalid = 20,
  Valid = 30,
  Available = 40,
  NotAvailable = 50,
  FailWithException = 60,
  InProgress = 70,
  Completed = 80,
}

