import {L10nSchema} from "angular-l10n/lib/models/types";

export const ACCESS_TOKEN_NAME = 'access-token';
export const REFRESH_ACCESS_TOKEN_NAME = 'refresh-access-token';
export const CURRENT_USER = 'current-user';
export const PAGE_SELECTED = 'page-select';
export const LANGUAGE = 'language'; //en-US, vi-VN ...
export const DEFAULTLANGUAGE = 'en-US'
export const APP_AUTH_TOKEN = 'auth_app_token'

export interface AppConfigs {
  apiUrl: string;
  defaultLanguage: string;
  defaultLocale: any;
  schema: Array<L10nSchema>;
  languages: any;
}
