import {Injectable} from '@angular/core';
import {BaseService} from '../utils/base.service';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {LoginRequest, LoginResponse, RefreshLoginRequest, RefreshResponse, VerifyToken} from '../models/log-in.model';
import {Observable, Subject} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AppService} from 'app/app.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  private readonly baseUrl: string;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    appService: AppService,
    httpBackend: HttpBackend,
    private readonly httpClient: HttpClient
  ) {
    super();
    this.baseUrl = `${appService.settings.apiUrl}`;
  }

  public checkTokenValid(token: string) {
    let url = `${this.baseUrl}/auth/token/verify/`
    return this.httpClient
      .post<VerifyToken>(url, {token})
      .pipe(catchError((error) => this.handleError(error)))
      .subscribe((res) => res)
  }

  public login(username: string, password: string, email: string) {
    return this.httpClient
      .post<LoginRequest>(`${this.baseUrl}/auth/login/`, {username, password, email})
      .pipe(catchError((error) => this.handleError(error)));
  }

  public refreshLogin(request: RefreshLoginRequest): Observable<RefreshResponse> {
    return this.httpClient
      .post<LoginResponse>(`${this.baseUrl}/auth/token/refresh/`, request)
      .pipe(catchError((error) => this.handleError(error)));
  }
}
