import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AuthGuard} from 'app/core/guards';
import {NbToastrModule} from '@nebular/theme';
import {resolveL10n} from "angular-l10n";


export const routes: Routes = [

  {
    path: 'auth',
    loadChildren: () => import('app/auth/auth.module').then((m) => m.NgxAuthModule),
    // resolve: { l10n: resolveL10n },

  },
  {
    path: 'login',
    redirectTo: 'auth/login',
    pathMatch: "full"

  },
  {
    path: 'register',
    redirectTo: 'auth/register',
    pathMatch: "full"

  },
  {
    path: 'logout',
    redirectTo: 'auth/logout',
    pathMatch: "full"

  }, {
    path: 'request-password',
    redirectTo: 'auth/request-password',
    pathMatch: "full"

  },
  {
    path: 'reset-password',
    redirectTo: 'auth/reset-password',
    pathMatch: "full"

  },
  {
    path: 'manager',
    loadChildren: () => import('./pages/manager/manager.module').then((m) => m.ManagerModule),
    canActivate: [AuthGuard],
    resolve: { l10n: resolveL10n },
    data: {
      l10nProviders: [{ name: 'lazy', asset: 'lazy' }]
    }
  },
  {
    path: '404',
    loadChildren: () => import('./pages/not-found/not-found.module').then((m) => m.NotFoundModule),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: "full"

  },
  {
    pathMatch: "full",
    path: 'home',
    loadChildren: () => import('app/home/home.module').then((m) => m.HomeModule)
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [
    RouterModule.forRoot(routes, config),
    NbToastrModule.forRoot(),
  ],
  exports: [RouterModule],

})
export class AppRoutingModule {
}
