import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthPageComponent} from "./auth.components";

export const routes: Routes = [
  {
    path: '',
    component: AuthPageComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: 'login',
        loadComponent: () => import('./login/login.component').then(m => m.LoginComponent),
      },
      {
        path: 'register',
        loadComponent: () => import('./register/register.component').then(m => m.RegisterComponent),

      },
      {
        path: 'logout',
        loadComponent: () => import('@nebular/auth').then(m => m.NbLogoutComponent),
        // component: NbLogoutComponent,
      },
      {
        path: 'request-password',
        loadComponent: () => import('./request-password/request-password.component').then(m => m.RequestPasswordComponent),
      },
      {
        path: 'reset-password',
        loadComponent: () => import('./reset-password/reset-password.component').then(m => m.ResetPasswordComponent),
        // resolve: { l10n: resolveL10n },
      },

    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),

  ],
  exports: [RouterModule],
})
export class AuthRoutingModule {
}
