import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {DEFAULTLANGUAGE, LANGUAGE} from 'app/app-configs';
import {AnalyticsService} from 'app/core/utils/analytics.service';
import {SeoService} from 'app/core/utils/seo.service';
import {LocalStorageService} from 'app/shared/services/localStorage.service';

import {NbIconLibraries} from "@nebular/theme";
import {L10N_CONFIG, L10N_LOCALE, L10nConfig, L10nLocale, L10nTranslationService} from "angular-l10n";
import {l10nConfig} from "./l10n-config";

@Component({
  selector: 'homies',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})
export class AppComponent implements OnInit {
  schema = this.config.schema;
  private current_language: string = window.localStorage.getItem(LANGUAGE) || DEFAULTLANGUAGE;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(L10N_CONFIG) private config: L10nConfig,
    private translation: L10nTranslationService,
    private analytics: AnalyticsService,
    private seoService: SeoService,
    private iconLibraries: NbIconLibraries,
  ) {
    this.iconLibraries.registerFontPack('fas', {packClass: 'fas', iconClassPrefix: 'fa'}); //solid
    this.iconLibraries.registerFontPack('far',{packClass: 'far', iconClassPrefix: 'fa'}); //regular
    this.iconLibraries.registerFontPack('fal', {packClass: 'fal', iconClassPrefix: 'fa'}); //light
    this.iconLibraries.registerFontPack('fad', {packClass: 'fad', iconClassPrefix: 'fa'}); //duotone
    this.iconLibraries.registerFontPack('fab', {packClass: 'fab', iconClassPrefix: 'fa'}); //brands
    this.iconLibraries.setDefaultPack('fad');

    // Set default locale.
    this.current_language = LocalStorageService.getInstance().getItem(LANGUAGE) || DEFAULTLANGUAGE;
    this.translation.setLocale(this.getCurrentOrDefault());
  }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.translation.init();
  }

  title(title: any) {
    throw new Error('Method not implemented.' + title.toString());
  }

  getCurrentOrDefault(): L10nLocale {
    let cur = l10nConfig.schema.find((x) => x.locale.language == this.current_language)
    return cur ? cur.locale : l10nConfig.defaultLocale
  }

  ngOnInit(): void {
    this.analytics.trackPageViews();
    this.seoService.trackCanonicalChanges();
  }
}
