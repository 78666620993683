import {Component, Input, OnInit} from '@angular/core';
import {NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';

import {LayoutService} from 'app/core/utils';
import {Router} from '@angular/router';
import {LocalStorageService} from 'app/shared/services/localStorage.service';
import {BaseComponent} from 'app/shared/components/base/base.component';
import {CURRENT_USER, LANGUAGE} from 'app/app-configs';
import {NbAuthService} from "@nebular/auth";
import {L10nTranslationService} from "angular-l10n";

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',

})
export class HeaderComponent extends BaseComponent implements OnInit {
  @Input() showToggleSideBar: boolean = true;
  user: any;
  language: any;
  currentTheme = 'default';

  userMenu = [
    {title: this.translateService.translate("Log Out"), key: 'log_out'},
  ];
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];
  userPictureOnly: boolean = false;
  showTranslate: boolean = false;
  constructor(
    private authService: NbAuthService,
    private menuService: NbMenuService,
    public router: Router,
    private themeService: NbThemeService,
    private sidebarService: NbSidebarService,
    private layoutService: LayoutService,
    private readonly translateService: L10nTranslationService,
  ) {

    super();
    this.menuService.onItemClick().subscribe((bag) => this.onContecxtItemSelection(bag));
  }

  loggedIn() {
    let a: boolean = false;
    this.authService.isAuthenticated().subscribe(isLoggedIn => a = isLoggedIn)
    return a
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.user = JSON.parse(LocalStorageService.getInstance().getItem(CURRENT_USER) || '{}');

    if (this.router.url.search("manager") === 1) {
      this.showTranslate = false
    }

  }

  onContecxtItemSelection(item: any) {
    this.language = LocalStorageService.getInstance().getItem(LANGUAGE);

    if (item.item.key === 'log_out') {
      LocalStorageService.getInstance().clearSessionStorage();
      this.router.navigate(['/login']);
    }

    LocalStorageService.getInstance().setItem(LANGUAGE, this.language);
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

}
