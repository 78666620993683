import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError as observableThrowError} from 'rxjs';
import {ResponseMessage} from '../models/response.message.model';
import {ResponseStatus} from '../models/response.status.model';

@Injectable()
export abstract class BaseService {
  constructor() {
  }

  public handleError(error: HttpErrorResponse): Observable<ResponseMessage | any> {
    if (!!error.status || error.status == null) {
      const responseMessage = new ResponseMessage();

      const errorMessages: string[] = [];

      switch (error.status) {
        case 0:
          errorMessages.push(error.message);
          break;
        case 400:
          const modelState = error.error;
          modelState.forEach((element: { errors: any[] }) => {
            element.errors.forEach((item) => {
              errorMessages.push(item.errorMessage);
            });
          });
          break;
        case 401: // Not Authorize
          errorMessages.push('Not authorizeeeeeeeeeeeeeee.');

          break;
        case 404: // Not Found
          errorMessages.push('Request not found.');
          break;

        case 500: // Internal Error
          const serverError = error.error;

          if (serverError.error && serverError.error.message && serverError.error.httpCode) {
            errorMessages.push(serverError.error.message);
            break;
          }

          errorMessages.push(error.message);
          break;

        default:
          errorMessages.push('Status: ' + error.status + ' - Error Message: ' + error.statusText);
          break;
      }

      responseMessage.status = ResponseStatus.FailWithException;
      responseMessage.message = errorMessages.join(';');

      return observableThrowError(responseMessage);
    }

    return observableThrowError(error);
  }
}
