import {Component, Inject} from '@angular/core';
import {NbAuthComponent, NbAuthService} from '@nebular/auth';
import {LocalStorageService} from "../shared/services/localStorage.service";
import {DEFAULTLANGUAGE, LANGUAGE} from "../app-configs";
// import {NbAuthService} from "@nebular/auth/services/auth.service";
import {Location} from "@angular/common";
import {L10N_CONFIG, L10N_LOCALE, L10nConfig, L10nLocale, L10nTranslationService} from "angular-l10n";

@Component({
  selector: 'auth',
  styleUrls: ['./auth.component.scss'],
  templateUrl: './auth.component.html',
})
export class AuthPageComponent extends NbAuthComponent {
  current_language = LocalStorageService.getInstance().getItem(LANGUAGE) || DEFAULTLANGUAGE;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(L10N_CONFIG) private config: L10nConfig,
    private translation: L10nTranslationService,
    // private locale: LocaleService,
    auth: NbAuthService,
    location: Location
  ) {
    super(auth, location)
    this.current_language = LocalStorageService.getInstance().getItem(LANGUAGE) || DEFAULTLANGUAGE;
    // translateService.setDefaultLang(this.current_language);
    // translateService.setTranslation(this.current_language, this.current_language, true);
    // locale.registerCulture(this.current_language);
  }

  setLocale(locale: L10nLocale): void {
    this.translation.setLocale(locale);
  }
}


