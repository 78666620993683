import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {LocalStorageService} from 'app/shared/services/localStorage.service';
import {ACCESS_TOKEN_NAME} from 'app/app-configs';

@Injectable({providedIn: 'root'})
export class NoAuthGuard implements CanActivate {
  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentCredentials = LocalStorageService.getInstance().getItem(ACCESS_TOKEN_NAME);

    if (currentCredentials) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}
