import {Injectable} from '@angular/core';
import {NbComponentStatus, NbGlobalLogicalPosition, NbGlobalPosition, NbToastrService} from '@nebular/theme';

@Injectable({providedIn: "root"})
export class ToastrService {
  private readonly duration = 0;
  private position: NbGlobalPosition = NbGlobalLogicalPosition.TOP_END;

  constructor(private toastrService: NbToastrService) {
  }

  public success(title: string, body: string = ''): void {
    this.showToast(title, body, 'success');
  }

  public error(title: string, body: string = ''): void {
    this.showToast(title, body, 'danger');
  }

  public warning(title: string, body: string = ''): void {
    this.showToast(title, body, 'warning');
  }

  public info(title: string, body: string = ''): void {
    this.showToast(title, body, 'info');
  }

  public basic(title: string, body: string = ''): void {
    this.showToast(title, body, 'basic');
  }

  public primary(title: string, body: string = ''): void {
    this.showToast(title, body, 'primary');
  }

  private showToast(title: string, body: string, type: NbComponentStatus) {
    const config = {
      status: type,
      destroyByClick: true,
      duration: this.duration,
      hasIcon: true,
      position: this.position,
      preventDuplicates: false,
    };

    this.toastrService.show(body, title, config);
  }
}
