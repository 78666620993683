<nb-select
  (selectedChange)="changeLanguage($event)" [(selected)]="selected"
  fullWidth="false"
  size="small"
>
  <nb-option
    *ngFor="let item of languages | keyvalue" [value]="item.key"
    size="small"
  >
    {{item.value}}
  </nb-option>

</nb-select>
