import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {
  NbActionsModule,
  NbButtonModule,
  NbContextMenuModule,
  NbIconModule,
  NbLayoutModule,
  NbOptionModule,
  NbSelectModule,
  NbSidebarModule,
  NbTagModule,
  NbThemeModule,
  NbUserModule,
} from '@nebular/theme';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {NbSecurityModule} from '@nebular/security';

import {FooterComponent, HeaderComponent,} from 'app/theme/components';
import {CapitalizePipe, NumberWithCommasPipe, PluralPipe, RoundPipe, TimingPipe,} from 'app/theme/pipes';
import {CommonLayoutComponent, OneColumnLayoutComponent} from 'app/theme/layouts';
import {DEFAULT_THEME} from 'app/theme/theme.default';
import {SharedModule} from 'app/shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {RouterLink} from "@angular/router";
import {L10nTranslateAsyncPipe} from "angular-l10n";

const NB_MODULES = [
  NbLayoutModule,
  NbActionsModule,
  NbSidebarModule,
  NbIconModule,
  NbEvaIconsModule,
  NbSecurityModule,
  NbUserModule,
  NbContextMenuModule,
  NbThemeModule,
  NbOptionModule,
  NbLayoutModule,
  NbSelectModule,
  NbButtonModule,
  NbTagModule,
];
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  OneColumnLayoutComponent,
  CommonLayoutComponent,
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
];
const nbThemeModule: any = NbThemeModule.forRoot(
  {
    name: 'default',
  },
  [DEFAULT_THEME,],
)

@NgModule({
  imports: [SharedModule, CommonModule, TranslateModule, ...NB_MODULES, RouterLink, NgOptimizedImage, L10nTranslateAsyncPipe],
  exports: [CommonModule, ...PIPES, ...COMPONENTS],
  declarations: [...COMPONENTS, ...PIPES],
})
export class ThemeModule {

  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        NbThemeModule
      ],
    };
  }
}
