import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {BaseComponent} from "app/shared/components/base/base.component";
import {NbAuthService} from "@nebular/auth";

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html',
})
export class FooterComponent extends BaseComponent implements OnInit {
  showTranslate: boolean = false;

  constructor(
    private router: Router,
    private authService: NbAuthService
  ) {
    super();
  }

  logged() {
    let a: boolean = false;
    this.authService.isAuthenticated().subscribe(isLoggedIn => a = isLoggedIn)
    return a
  }

  ngOnInit() {
    if (this.router.url.search("manager") === 1) {
      this.showTranslate = false
    }
  }

}
