<nb-layout>
  <nb-layout-header fixed>
    <ngx-header></ngx-header>
  </nb-layout-header>
  <nb-layout-column>
    <nb-card>
      <!--      <nb-card-header>-->
      <!--        <nav class="navigation">-->
      <!--          <a (click)="back()" aria-label="Back" class="link back-link" href="#">-->
      <!--            <nb-icon icon="arrow-back"></nb-icon>-->
      <!--          </a>-->
      <!--        </nav>-->
      <!--      </nb-card-header>-->
      <nb-card-body>
        <nb-auth-block>
          <router-outlet></router-outlet>
        </nb-auth-block>
      </nb-card-body>
    </nb-card>
  </nb-layout-column>
  <nb-layout-footer>
    <ngx-footer></ngx-footer>
  </nb-layout-footer>
</nb-layout>
