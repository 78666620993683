import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfigs, DEFAULTLANGUAGE, LANGUAGE} from 'app/app-configs';


@Injectable()
export class AppService {
  lang: string;

  private path = `assets/app.config.json`;
  private configSettings: AppConfigs = {
    apiUrl: '',
    defaultLanguage: DEFAULTLANGUAGE,
    defaultLocale: {},
    languages: [],
    schema: []
  };

  constructor(
    private readonly httpClient: HttpClient,
  ) {
    this.lang = window.localStorage.getItem(LANGUAGE) || DEFAULTLANGUAGE
  }

  get settings() {
    return this.configSettings;
  }

  public load(): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient.get(this.path)?.subscribe((response: any) => {
        this.configSettings = {
          apiUrl: response.apiUrl,
          defaultLanguage: this.lang ? this.lang : response.defaultLanguage,
          schema: response.schema,
          defaultLocale: response.defaultLocale,
          languages: response.languages,
        };
        resolve(true);
      });
    });
  }
}

